/* src/components/PieChart/PieChart.css */

.pie-chart-container {
    background-color: #fff; /* Background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.050); /* Shadow */
    padding: 20px;
    margin: 20px 0;
    width: 590px;
    text-align: center; /* Center-align text */
  }
  
  .pie-chart-container h3 {
    font-size: 18px;
    color: #333; /* Text color */
    margin-bottom: 20px;
  }

  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  