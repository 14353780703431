/* src/components/CustomField/PasswordInputField.css */

/* Apply the same styles as CustomInputField.css */
.custom-form-item .ant-form-item-label > label {
    color: #000000; 
    font-size: 18px;
    font-weight: 500;
}

.custom-input {
    width: 559px; /* Make input field responsive */
    height: 70px; 
    border-color: #9F9F9F !important; 
    border-radius: 10px; 
    padding-right: 120px; /* Space for the reset link */
    box-sizing: border-box; /* Ensure padding does not affect width */
}

.custom-input:hover {
    border-color: #1890ff !important;
}

.custom-input:focus {
    border-color: #1890ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

/* Specific styles for the password field container and reset link */
.password-container {
    position: relative; /* Needed for positioning the reset link */
}

.reset-password {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #0254FF;
    cursor: pointer;
    font-size: 16px;
}

.reset-password:hover {
    text-decoration: underline;
}
