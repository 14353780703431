.sales-order-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}

.empty-field {
    display: flex;
    position: absolute;
    top: 200px;
    left: 710px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box {
    width: 150px;
    height: 150px;
}

.upper-text {
    color: blue;
    font-size: 30px;
    font-weight: 700;
}

.lower-text {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
}

.add-button {
    margin: 20px;
    width: 150px;
    height: 40px;
}

.export-button {
    border-radius: 5px;
    font-weight: 500;
    color: blue;
    width: 150px;
    height: 40px;
    border: solid 1px rgb(126, 126, 255);
}

.header-action {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

/* Styles for the table container to cover the entire area */
.table-container {
    width: 96%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

/* Search and filter container styles */
.search-filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    margin-top: 30px;
    gap: 10px; /* Space between search and filter */
}

.filter {
    color: #7e7e7e;
    margin-left: 10px;

}

.sales-order-table .ant-table-tbody > tr:hover {
    background: #f5f5f5; /* Light grey background on hover */
    cursor: pointer; /* Pointer cursor on hover */
}

.sales-order-table .ant-table-tbody > tr {
    transition: transform 0.2s; /* Smooth transition for zoom effect */
}

.sales-order-table .ant-table-tbody > tr:hover {
    transform: scale(1.02); /* Zoom effect on hover */
}