.customer-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 10px;
    width: 100%;
}

.header-action {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.component-container {
   display: flex;
   gap: 20px;
}