/* Container for the entire login page */
.login-container {
    display: flex;
    width: 100vw;
    height: 100vh;
  }
  
  /* Container for the image on the left side */
  .image-container {
    flex: 1; /* Takes up 50% of the width */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e2e2e2; /* Optional: background color for the image container */
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  
  /* Container for the form on the right side */
  .form-container {
    flex: 1; /* Takes up 50% of the width */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 50px;
  }
  
  .title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-left: 150px;
  }
  
  .inputsField {
    width: 100%; /* Make the input fields span the full width of the form container */
  }

  .account {
    font-size: 18px;
    font-weight: 600;
    margin-left: 150px;
  }
  
  .signUp {
    font-size: 18px;
    font-weight: 600;
    color: #0254FF;
    margin-left: 10px;
    cursor: pointer;
  }

  .signUp:hover {
    text-decoration: underline;
  }
  

  /* Media Queries */
  
  /* For small screens (mobile): Remove the image */
  @media (max-width: 768px) {
    .image-container {
      display: none;
    }
  
    .form-container {
      flex: 1; /* Make the form container take up the full width */
      padding: 0 20px; /* Adjust padding for smaller screens */
    }

    .title {
        margin-left: 30px;
    }

    .account {
        margin-left: 30px;
    }
  }
  
  /* For medium screens (tablet): Reduce the image size */
  @media (min-width: 769px) and (max-width: 1024px) {
    .image-container {
      flex: 0.5; /* Reduce the image container width */
    }
  
    .image-container img {
      width: 100%; /* Reduce image width */
      height: 100%; /* Reduce image height */
      object-fit: cover;
    }
  }
  