.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: sticky;
    top: 0;
    height: 80px; /* Standard header height */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
    z-index: 1000; /* Ensure header stays on top */
    background-color: #fff; /* Ensure background color for better visibility */
    width: 100%; /* Make sure header uses full width */
    box-sizing: border-box; /* Ensure padding is included in the width */
}

.leftSection {
    display: flex;
    align-items: start;
    margin-left: 0; /* Ensure no margin is pushing the logo away */
}
.logo {
    width: 120px;
    margin-left: 10px;
}

.middleSection {
    display: flex;
    justify-content: center;
    flex-grow: 1; /* Ensures the middle section takes up available space */
    padding: 0 20px; /* Space between sections */
}

.search-box {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px; /* Limit the width of the search bar */
    position: relative; /* Required for positioning the search icon */
}

.search-input {
    width: 100%;
    height: 50px; /* Adjust height as needed */
    padding-right: 40px; /* Space for the search icon */
    padding-left: 10px;
    border-radius: 8px; /* Rounded corners for the search box */
    border: 1px solid #d9d9d9; /* Border for input */
    box-sizing: border-box; /* Ensure padding is within width */
}

.search-icon {
    position: absolute;
    right: 10px;
    font-size: 24px;
    color: #222222;
    pointer-events: none; /* Prevent click on icon */
}

.rightSection {
    display: flex;
    align-items: center;
    gap: 20px; /* Space between elements in right section */
}

.initials-cycle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0254FF;
    border-radius: 50%; /* Fully rounded to make a circle */
    width: 54px;
    height: 54px;
}

.initials-letter {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.userInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.user-name {
    font-size: 16px;
    font-weight: 700;
}

.company-name {
    font-size: 14px;
    font-weight: 500;
    color: #535353;
}

.more-icon {
    font-size: 24px;
    color: #222222;
    cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
    .middleSection {
        display: none; /* Hide the left section on small screens */
    }

    .rightSection .userInfo {
        display: none; /* Hide the username and company name on small screens */
    }

    .container {
        padding: 0 10px; /* Reduce padding on small screens */
        height: 60px; /* Adjust header height on small screens */
    }

    .search-box {
        max-width: 300px; /* Reduce search box width on small screens */
    }

    .initials-cycle {
        width: 40px; /* Reduce initials circle size on small screens */
        height: 40px;
    }
    
    .search-input {
        height: 40px; /* Adjust search input height on small screens */
    }
}
