/* src/layout/MainLayout.css */

.main-layout {
    display: flex;
    flex-direction: column;
    background-color: #FBFBFB; /* Set the background color */
    min-height: 100vh;
  }
  
  .layout-body {
    display: flex;
    flex: 1;
  }
  
  .main-content {
    flex-grow: 1;
    margin-left: 250px; /* Adjust according to Sidebar width */
    padding: 20px;
  }
  