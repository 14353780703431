.analytics-container {
    padding: 20px;
    margin: 20px 0;
    background-color: #ffffff;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.050); /* Shadow */
  }
  
  .analytics-title {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .chart-container {
    width: 680px;
    height: 300px;
  }
  