.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
 }
 
 h2 {
     font-size: 24px;
     font-weight: 600;
     color: #333;
     margin-bottom: 20px;
   }
 
 .stats-cards-container {
     display: flex;
     flex-wrap: wrap; /* Allows cards to wrap onto new lines */
     gap: 20px; /* Space between cards */
   }
 
 .charts-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
 }