/* src/components/Sidebar/Sidebar.css */

.sidebar {
    width: 250px; /* Default width for larger screens */
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: fixed;
    height: 100%;
    top: 1;
    left: 0;
    background-color: transparent; /* No background color */
    box-shadow: none; /* Remove box shadow */
    transition: width 0.3s ease; /* Smooth transition for width changes */
    margin-top: 40px;
  }
  
  .sidebar-nav {
    flex-grow: 1;
  }
  
  .sidebar-nav ul {
    list-style-type: none; /* Remove default list dots */
    padding: 0;
    margin: 0;
  }
  
  .sidebar-nav li {
    margin-bottom: 20px; /* Space between items */
  }
  
  .sidebar-nav a {
    display: flex;
    align-items: center;
    text-decoration: none; /* Remove underline */
    color: #333; /* Text color */
    font-size: 18px;
    font-weight: 400;
    transition: color 0.3s ease, background-color 0.3s ease;
    padding: 10px; /* Add padding for touch targets */
  }
  
  .sidebar-nav a.active, .sidebar-nav a:hover {
    color: #0254FF; /* Change color for active and hover links */
    border-radius: 8px; /* Rounded corners for active link */
  }
  
  .sidebar-nav .anticon {
    font-size: 24px; /* Icon size */
    margin-right: 10px; /* Space between icon and text */
  }
  
  .sidebar-nav .sidebar-label {
    margin-left: 10px; /* Space between icon and text */
    display: inline; /* Show labels by default */
  }
  
  /* Media query for small screens */
  @media (max-width: 768px) {
    .sidebar {
      width: 60px; /* Reduced width for small screens */
    }
  
    .sidebar-nav a {
      justify-content: center; /* Center icons */
      padding: 10px; /* Add padding to improve touch targets */
    }
  
    .sidebar-nav .sidebar-label {
      display: none; /* Hide labels by default on small screens */
    }
  
    .sidebar-nav a:hover .sidebar-label {
      display: inline-block; /* Show labels on hover */
      position: absolute; /* Position the label above the icon */
      left: 70px; /* Position the label to the right of the icon */
      white-space: nowrap; /* Prevent label from wrapping */
      background-color: #7557fc; /* Background color for the label */
      color: #fff; /* Text color for the label */
      padding: 5px 10px; /* Padding around the label */
      border-radius: 4px; /* Rounded corners for the label */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional shadow for the label */
    }
  }
      