.getStarted{
    flex: auto;
    align-items: center;
    justify-content: center;
}

img{
    margin: 100px;
    width: 10%;
}

.barSteps{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
    width: 35%;
}

.bar{
    width: 120px;
    height: 9px;
    flex-shrink: 0;
    background: #0254FF;
}

.barGray{
    width: 120px;
    height: 9px;
    flex-shrink: 0;
    background: #D9D9D9;
}

.titleGuide{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 50px;
}

.titleHeader{
    font-size: 30px;
    font-weight: 700;
}

.guideText{
    font-size: 20px;
    font-weight: 400;
}

.inputsField {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; /* Optional: Full width */
    margin-top: 20px; /* Space between title and input */
}