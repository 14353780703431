.edit-card-container {
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 700px;
    margin: 0 auto;
    margin-top: 20px;
  }
  
  .form-header {
    text-align: left; /* Align the header to the left */
    margin-bottom: 20px;
  }
  
  .edit-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    align-items: flex-start;
    width: 100%;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: gray; /* Set label color to gray */
    text-align: left;
    width: 100%;
  }
  
  .form-control {
    width: 100%;
    height: 40px;
  }
  
  .update-button {
    padding: 10px;
    background-color: #007BFF; /* Blue color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 150px; /* Specific width for the button */
    height: 40px; /* Ensure same height */
    align-self: flex-start; /* Align the button to the left */
    margin-top: 20px;
  }
  
  .update-button:hover {
    background-color: #0056b3;
  }
  
  .row-format {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
  }
  