/* Increase the width of the modal */
.add-customer-modal .ant-modal {
    width: 700px !important; /* Adjust the width to your preference */
  }
  
  /* Center the modal */
  .add-customer-modal .ant-modal-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Style for the form container */
  .add-customer-modal .ant-modal-body {
    padding: 24px;
  }
  
  /* Style for the input fields */
  .add-customer-modal .ant-form-item {
    margin-bottom: 16px;
  }
  
  .add-customer-modal .ant-input {
    height: 40px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    transition: all 0.3s ease;
  }
  
  .add-customer-modal .ant-input:focus,
  .add-customer-modal .ant-input:hover {
    border-color: #40a9ff;
    box-shadow: 0 0 8px rgba(64, 169, 255, 0.2);
  }
  
  /* Reduce the width of the submit button */
  .add-customer-modal .ant-btn-primary {
    width: 150px; /* Reduced width */
    height: 45px;
    background-color: #1890ff;
    border-color: #1890ff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.3s ease;
    margin-top: 20px;
  }
  
  .add-customer-modal .ant-btn-primary:hover,
  .add-customer-modal .ant-btn-primary:focus {
    background-color: #40a9ff;
    border-color: #40a9ff;
    box-shadow: 0 0 8px rgba(64, 169, 255, 0.5);
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .add-customer-modal .ant-modal {
      width: 90% !important; /* Modal width adjusts on smaller screens */
    }
  
    .add-customer-modal .ant-btn-primary {
      width: 100%; /* Full width button on mobile */
    }
  }
  