.sales-details-page {
    padding: 20px;
  }
  
  .header-action {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sales-title {
    font-weight: 600;
    color: #333;
  }
  
  .info-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .info-card .ant-descriptions-item-label {
    font-weight: 500;
  }
  
  .info-card .ant-descriptions-item-content {
    color: #555;
  }
  
  .ant-divider {
    margin: 20px 0;
  }
  