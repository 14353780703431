.sales-order-form {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
  
  .sales-order-cards {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .customer-info-card, .items-card {
    width: 100%;
    background-color: white;
    border-radius: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
  }
  
  .item-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .sales-order-actions {
    margin-top: 30px;
    display: flex;
    gap: 20px;
  }
  
  .items-card {
    width: 100%;
  }
  
  .add {
    margin-top: 34px;
  }
  .added-items {
    margin-top: 20px;
  }
  
  .added-item-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .delete-icon {
    color: red;
    cursor: pointer;
  }
  