.card-container {
    background-color: #fff; /* Background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.050); /* Shadow */
    padding: 30px 50px;
    margin-top: 20px;
}

.customer-details-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.initials-cycle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0254FF;
    border-radius: 50%; /* Fully rounded to make a circle */
    width: 54px;
    height: 54px;
}

.initials-letter {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.details {
    margin-top: 10px;
}

.details-colunms {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}

.email-title {
    font-size: 16px;
    font-weight: 600;
}

.email-details {
    font-size: 15px;
    font-weight: 400;
    color: #7e7e7e;
    margin-left: 50px;
}