/* src/components/StatsCard/StatsCard.css */

.stats-card {
    background-color: #fff; /* Card background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.014); /* Card shadow */
    display: flex;
    align-items: center;
    padding: 15px;
    width: 300px;
    height: 150px;
    transition: box-shadow 0.3s ease;
  }
  
  
  .stats-card-icon {
    font-size: 24px; /* Icon size */
    color: #0254FF; /* Icon color */
    margin-left: 40px; /* Space between icon and text */
  }
  
  .stats-card-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 30px;
  }
  
  .stats-card-title {
    font-size: 20px;
    font-weight: 400;
    color: #333; /* Title color */
    margin: 0;
  }
  
  .stats-card-value {
    font-size: 30px;
    font-weight: 700;
    color: #000; /* Value color */
    margin: 0;
  }
  