.continue-button {
    width: 559px;
    height: 70px;
    background-color: #0254FF; 
    color: #fff;
    border: none; 
    border-radius: 10px; 
    font-size: 18px; 
    font-weight: 600; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease; 
  }
  
  .continue-button:hover {
    background-color: #003cbf;
    border: #003cbf;
  }
  
