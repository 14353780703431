.quotation-template {
    border: 1px solid #eaeaea;
    border-radius: 5px;
    background: #fff;
    width: 70%;
    margin: auto; /* Center the template */
    padding: 2rem 0;
}

.date-logo,
.sender-receiver-container,
.quotation-section,
.quotation-summary,
.quotation-payments,
.quotation-notes,
.signature-section {
    text-align: left;
    padding: 0 2rem; /* Ensure all sections have left alignment */
}

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.line {
    width: 60%;
    border: 3px solid #0f0330;
}

.line-divide {
    width: 100%;
    border: 1px solid #0f0330;
    margin: 20px 0; /* Add margin for spacing */
}

.to-rece {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
}

.to-text {
    margin-left: 3rem;
    font-weight: 800;
}

.container-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
}

.title-box {
    width: 40%;
    height: 100px;
    background-color: #0f0330;
    align-items: center;
    justify-content: center;
    border-radius: 60px 0 0 60px;
}

.title {
    color: #fff;
    margin-right: 10rem;
}

.date-logo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo-display {
    width: 30%;
}

.quotation-section {
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
}

.quotation-item {
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

.summary {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-weight: bold;
}

h2,
h4 {
    color: #333;
}

strong {
    color: #555;
}

.line-vertical {
    border-left: 1px solid #ccc; /* Adjust color as needed */
    height: 100%; /* Make it occupy full height of the container */
    margin: 0 20px; /* Space between sections */
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.payments {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
}

/* Signature Section Styles */
.signature-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px; /* Space before signature */
    padding-top: 20px; /* Space above signature */
}

.signature-info {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.signature-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start */
    margin-top: 10px;
}

.sign {
    border-top: 1px dashed #555; /* Dashed line for signature */
    width: 200px; /* Width of the signature line */
    margin-top: 10px; /* Space above signature line */
}

.disclaimer {
    font-size: 12px; /* Smaller text for disclaimer */
    color: #777; /* Light gray color for disclaimer */
    margin-top: 5px; /* Space above disclaimer */
}
